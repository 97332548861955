/* Style the accordion section */

/* Style the buttons that are used to open and close the accordion panel */

.custom-accordion-button {
  /* background-color: transparent; */
  color: #444;
  cursor: pointer;
  border: 4px;
  border-color: white;
  border-style: solid;
  background-color: red;
  /* background-color: white; */
  transition: background-color 0.25s ease;
  height: 100%;
}

.accordion-content {
  /* overflow: auto; */
  overflow-y: hidden;
  transition: max-height 0.6s ease;
}

.accordion-title {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}

.accordion-content-text {
  font-weight: 400;
  font-size: 14px;
  padding: 0px 15px 15px 15px;
}

.switch-button {
  color: rgb(245, 146, 146);
  color: red;
  text-decoration: none;
}

.switch-button:hover {
  color: red;
  text-decoration: underline;
}

.auth-input {
  background: rgba(153, 153, 153, 0.76);
}

.auth-input:focus {
  background: rgba(153, 153, 153, 0.76);
}

.auth-button {
  background-color: red;
  border: red;
}

.auth-button:hover {
  background-color: rgb(211, 3, 3);
  border: rgb(211, 3, 3);
}

.auth-button:active {
  background-color: rgb(211, 3, 3) !important;
}

.auth-button:focus {
  background-color: rgb(211, 3, 3) !important;
}

.auth-button:active {
  background-color: rgb(211, 3, 3) !important;
}

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.space-top {
  margin-top: 30px;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.side_list {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.side_list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.sidebar-hidden {
  transition: ease-in-out 0.4s;
  padding: 0px;
  width: 0px;
}

.sidebar-visible {
  /* width: 100px; */
  transition: ease-in-out 0.4s;
  border: black 1px solid;
}

.hide-overflow {
  overflow: hidden;
  white-space: nowrap;
}

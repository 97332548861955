.event-button {
  width: 87%;
  overflow: hidden;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}
.event-button:hover {
  background-color: rgb(224, 224, 224);
}

.event-delete-button {
  margin-left: auto;
  width: 10%;
  background-color: black;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.event-delete-button:hover {
  background-color: rgb(255, 0, 0);
}

:root {
  --background-color: rgb(0, 0, 0);
  --nav-item-color: #c0c0c0;
  --button-color: rgb(0, 174, 255);
  --custom-text-color: white;
}

body {
  margin: 0;
}

.custom-nav-item {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.custom-nav-links {
  color: var(--custom-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.custom-nav-links:hover {
  background-color: var(--nav-item-color);
  border-radius: 15px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.custom-nav-button {
  color: var(--custom-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
}

.custom-nav-button:hover {
  background: var(--nav-item-color);
  color: black;
  transition: 250ms;
  padding: 0.5rem 1rem;
}

.nav-active {
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 15px;
}

.dropdown-hover {
  background-color: var(--nav-item-color);
  border-radius: 15px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--custom-text-color);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 960px) {
  .custom-nav-menu-desktop {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    /* margin-top: 10px; */
    text-align: center;
    /* width: 90vw; */
    justify-content: end;
    /* margin-right: 2rem; */
  }

  .nav-desktop-menu {
    display: none;
  }

  .accordion-section {
    display: none;
    /* background-color: tomato;  */
  }
}

@media screen and (max-width: 960px) {
  .nav-desktop-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    /* margin-top: 10px; */
    text-align: center;
    /* width: 90vw; */
    justify-content: end;
    /* margin-right: 2rem; */
  }

  .custom-nav-menu-desktop {
    display: none;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--nav-item-color);
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--custom-text-color);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    width: 100%;
    text-decoration: none;
    color: black;
    font-size: 15px;
    border-radius: 20px;
    padding: 15px;
    margin: 0px;
  }

  .nav-links-mobile:hover {
    background: var(--nav-item-color);
    color: black;
    text-decoration: none;
    transition: 250ms;
    border-radius: 20px;
  }

  .accordion-section {
    display: flex;
    /* flex-direction: column; */
    background-color: red;
  }
}

.accordion-content {
  /* overflow: auto; */
  overflow-y: hidden;
  width: 100%;
  transition: max-height 0.6s ease;
}

.dropdown-nav-button {
  color: var(--custom-text-color);
  text-align: left;
  width: 100%;
  text-decoration: none;
  color: black;
  font-size: 15px;
  border-radius: 20px;
  padding: 15px;
}

.dropdown-nav-button:hover {
  background-color: #dcdcdc;
  transition: 250ms;
}
